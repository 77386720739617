<template>
  <div class="title">
    <h1>Nos réseaux</h1>
  </div>    
  <div class="container">
    <a href="https://www.instagram.com/ekosavewater/"><img src="../assets/logo_instagram.png"></a>
    <a href="https://www.linkedin.com/company/ek-o-save-water/"><img src="../assets/logo_linkedin.png"></a> 
    <a href="mailto:ekosavewater@gmail.com"><img src="../assets/logo_mail.png"></a> 
  </div>
  <div class="container-infos">
    <div class="carte">
      <img src="../assets/carte.png">
    </div>
    <div class="infos">
      <div class="address">
        <img src="../assets/icone_emplacement.png">
        <h3>1000 route du Porage, 49330 Etriché, France</h3>
      </div>
      <div class="sep"></div>
      <a href="mailto:ekosavewater@gmail.com"><div class="mail">
        <img src="../assets/logo_mail_black.png">
        <h3>ekosavewater@gmail.com</h3>
      </div></a>      
    </div>
  </div>
</template>

<style scoped>  
  .title {
    text-align: center;
    font-size: large;
    padding-top: 5em;
    
  }

  .carte{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .carte img {
    width: 20%;
  }

  .infos img {
    width: 30px;
    margin: 20px;
  }

  .container-infos {
    padding-bottom: 7em;
  }

  .address h3 , .mail h3 {
    width: 60%;
    color: rgb(255, 255, 255);
  }
  .infos {
    /* Ajouter box-sizing */
    margin-top: 5%;
    display: block;
    box-sizing : border-box;
    padding:10px;
    width:80%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    background: rgb(162, 199, 255);
    border-radius: 10px;
  }

  .address, .mail {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  

  .sep {
    background-color: #ffffff;
    height: 4px;
    width: 50px;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding-bottom: 5px;
}

  .container {
    /* Ajouter box-sizing */
    box-sizing : border-box;
    border-radius:5em;
    padding:10px;
    width: 70%;
    /* redéfinition 400 + 2*20 */
    max-width: 440px;
    text-align: center;
    background-color: white;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    margin:0 auto;
    margin-bottom: 5%;
  }

  h1 {
    color: white;
    margin-bottom: 2%;
  }

  .container label {
    color: black;
  }
  
  h2 {
    color: rgb(0, 0, 0);
  }

  .container img {
    width: 5em;
    padding: 2px;
  }
</style>