<template >
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P3GWF4VZ"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <div class="head">
        <div class="icon">
            <router-link to="/"><img src="../assets/eko.svg"> </router-link>
        </div>
        <div class="items">
            <ul>
                <li><router-link  to="/eko">Produit</router-link></li>                        
                <li><router-link  to="/team">À propos</router-link></li>
                <li><router-link  to="/contact">Contact</router-link></li>
            </ul>
        </div>
    </div>
    <!-- <div class="scrolling-container">
        <div class="scrolling-text">
            <h3><font color="#009FE3">Ek'o</font> a reçu le <strong>prix de l'OMPI</strong> ainsi que la <strong>médaille d'or du Concours Lépine</strong> (édition 2024)</h3>
        </div>
    </div> -->
    


</template>


<style  scoped>
    .custom-select {
    font-weight: bold;
    font-size: large;
    border: 2px solid black;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    color: black;
    cursor: pointer;
    }

    .custom-select:hover {
        color: black;
    }

    .custom-select:focus {
        outline: none;
        border-color: blue; /* Couleur de la bordure lorsqu'il est en focus */
    }


    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: hidden;
        background-color: white;
        padding: 0.5em 5%;
        top: 0;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.584);
        position: fixed;
        z-index: 1; /* Un z-index plus bas */
    }

    .icon {
        width:6em;
    }

    .items {
        display: inline-block;
        align-items: center;
        width: 100%;
        position: right;
    }

    ul {
        display: inline-flex;
        list-style-type : none;
        padding-inline-start: 5px;

    }

    h3 {
        font-style: italic;
        font-weight: 300;
        font-size: large;
    }

    li {
        font-weight: bold;
        font-size: large;
        margin-left: 1em;
    }

    a{
        color: rgb(120, 119, 119);
    }
    a:hover {
        color: black;
    }

    .scrolling-container {
        overflow: hidden;
        width: 100%;
        position: relative;
        background-color: rgb(98, 252, 55);
        margin-bottom: 3em;
    }

    .scrolling-text {
        white-space: nowrap;
        animation: scroll-left 15s linear infinite;
        width: 100%;
        color: black;
    }

    @keyframes scroll-left {
        0% { transform: translateX(100%); }
        100% { transform: translateX(-650px); }
    }

    .sep {
        background-color: #62ff00;
        height: 1px;
        width: auto;
        margin: 0 auto;
        border-radius: 4px;
        padding-bottom: 5px;
    }

    
</style>

<script setup>
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-P3GWF4VZ');

</script>

