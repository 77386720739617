<template >
    <div class="footer">
        <div class="icon">
            <router-link to="/"><img src="../assets/eko.svg"> </router-link>
        </div>
        <div class="socials">
            <a href="https://www.instagram.com/ekosavewater/"><img src="../assets/logo_instagram_black.png"></a>
            <a href="https://www.linkedin.com/company/ek-o-save-water/"><img src="../assets/logo_linkedin_black.png"></a> 
            <a href="mailto:ekosavewater@gmail.com"><img src="../assets/logo_mail_black.png"></a> 
        </div>
    </div>
</template>


<style  scoped>
    .icon {
        width: 6em;
        margin: auto;
        padding-top: 10px;
    }

    .footer {
        display: flexbox;
        background-color: rgb(255, 255, 255);
        position: relative;
        bottom: 0;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
    }

    .left {
        display: flex;
        justify-content: left;
        padding: 10px;
    }

    .socials img {
        width: 20px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .socials {
        padding: 10px;
        display: flex;
        justify-content: center;
    }
</style>

<script setup>
</script>

