import { createRouter, createWebHistory } from 'vue-router';
import Contact from '../views/Contact-view.vue';
import Home from '../views/Home-view.vue';
import Eko from '../views/Eko-view.vue';
import NotFound from '../views/Not-found.vue';
import Team from '../views/Team-view.vue';

const router = createRouter({
  history: createWebHistory(""),
  routes: [
    { 
      path: '/:pathMatch(.*)*',
      name: '404 Not Found',
      component: NotFound 
    },
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/contact',
      name: 'contact',
      meta: { transition: 'slide-left' },
      component: Contact
    },
    {
      path: '/eko',
      name: 'eko',
      component: Eko
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: NotFound
    },
    {
      path: '/team',
      name: 'team',
      component: Team
    }
  ]
})

export default router
