<template>
    <div class="title">
      <h1>404 Not Found !</h1>
      <img src="../assets/notfound.png">
    </div>
</template>

<style scoped>

.title {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 1em;
  font-weight: 900;
  width: 70%;
  font-size:xx-large;
  color: black;
}

img {
    margin: auto;
    margin-top: 3em;
}

</style>

